html, body {
    height: 100%;
}

body {
    margin: 0px;
    padding: 0px;
    background-image: url('/img/bg-body.png');
    background-position: top right;
    font-family: sans-serif;
    color: #565656;
    font-size: 1.3em;
}

input, button {
    font-family: sans-serif;
}

#heading {
    height: 150px;
    background-image: url('/img/bg.png');
    background-repeat: no-repeat;
    background-position: bottom left;
    position: relative;
    border-bottom: solid 1px #e3e3e2;
}

#logo {
    position: absolute;
    right: 1%;
    bottom: 6%;

    img {
        width: 90px;
    }
}

#loginButton {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #333333;
}

.loading {
    #langSelect, .validateMessage {
        display: none !important;
    }

    .errorInput {
        border-color: #e3e3e2 !important;
    }
}

#langSelect {
    position: absolute;
    top: 5px;
    right: 0px;
    color: #333333;

    span {
        text-transform: uppercase;
        display: inline-block;
        cursor: pointer;
        font-size: 0.9em;
        color: #333333;
        border-right: solid 1px #333333;
        padding: 0px 0.7em;

        &:last-child {
            border-right: none;
        }

        &.selected {
            cursor: default;
            font-weight: bold;
        }
    }
}

h1, h2, h3, h4 {
    margin: 0px 0px 1em 0px;
    padding: 0px;
    font-size: 2em;
}

h2 {
    font-size: 1.6em;
}

h3 {
    font-size: 1.4em;
}

h4 {
    font-size: 1.2em;
}

a, .anchor {
    cursor: pointer;
    color: #333333;
    text-decoration: none;
}

a:hover, .anchor:hover {
    color: #bf342d;
}

.iconAnchor {
    font-size: 1.5em;
    display: inline-block;
}

.codeSwitch {
    position: relative;
    top: 0px;
    top: 4px;
    padding: 0px 5px;
}

#content {
    padding: 50px 0px;
    margin-bottom: 30px;
    position: relative;
}

.center {
    text-align: center;
}

input {
    width: 70%;
    padding: 15px;
    font-size: 1em;
    border: solid 1px #e3e3e2;
    margin-bottom: 20px;
}

input:focus,
textarea:focus,
button:focus {
    outline:none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

button {
    background: #d3db38;
    background: -moz-linear-gradient(top, #d3db38 0%, #9fa521 100%);
    background: -webkit-linear-gradient(top, #d3db38 0%,#9fa521 100%);
    background: linear-gradient(to bottom, #d3db38 0%,#9fa521 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3db38', endColorstr='#9fa521',GradientType=0 );
    color: #333333;
    text-transform: uppercase;
    padding: 0.7em 1.4em;
    font-size: 1em;
    border-width: 0px;
    min-width: 15em;
    text-align: center;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background: #d3db38;
    }

    &.smallButton {
        min-width: 50px;
        padding: 0.6em 0.8em;
        font-size: 0.9em;
    }

    &.subdued {
        background: #c9cfd6;
        background: -moz-linear-gradient(top, #c9cfd6 0%, #d7dade 100%);
        background: -webkit-linear-gradient(top, #c9cfd6 0%,#d7dade 100%);
        background: linear-gradient(to bottom, #c9cfd6 0%,#d7dade 100%);

        &:hover {
            background: #d7dade;
        }
    }
}

button[disabled], button.disabled {
    background: #cccccc;
    color: #666666;
    cursor: default;
}

button[disabled]:hover, button.disabled {
    background: #cccccc;
}

button.pointer {
    cursor: pointer;
}

.formInput {
    width: 70%;
    margin: 0px auto 30px auto;
    position: relative;

    input {
        width: 100%;
        padding: 15px 0px;
        margin: 0px 0px 0.2em 0px;
        text-align: center;
        background-image: none !important;
    }

    label {
        display: block;
        text-align: left;
        margin: 0px 0px 5px 5px;
    }

    .validateMessage {
        text-align: right;
        color: #bf342d;
        font-size: 0.8em;
    }

    .errorInput {
        border-color: #bf342d;
    }

    .scanIcon {
        position: absolute !important;
        right: 0.3em;
        margin-top: 12px;
        z-index: 1;
    }

    .scanIcon2 {
        position: absolute !important;
        right: 2.9em;
        margin-top: 14px;
        z-index: 1;
    }

    .inputContainer {
        position: relative;
        margin-bottom: 1em;
    }

    .flashMessage {
        margin: 0px 0em 1.5em 0em;

        .inner {
            padding: 0.7em 2.5em 0.7em 1em !important;
        }
    }
}


.flashMessage {
    margin: 0px 1.5em 1.5em 1.5em;
    position: relative;
    border: solid 1px;

    .inner {
        text-align: center;
        padding: 0.7em 2.5em 0.7em 1em;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 7px;
        cursor: pointer;
        font-size: 1.3em;
    }

    &.fullScreen {
        position: fixed;
        z-index: 999;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        margin: 0px;
        border-width: 0px;
        overflow: auto;

        .inner {
            padding: 0px 0px 10% 0px;
        }

        .close {
            right: 1em;
            top: 1em;
            font-size: 1.5em;
        }
    }

    &.common {
        background-color: #e7e7e6;
        border-color: #666666;
        color: #333333;
        background-image: url('/img/bg-body.png');
    }

    &.info {
        background-color: #dff0d8;
        border-color: #d0e9c6;
        color: #3c763d;
    }

    &.alert {
        background-color: #fcf8e3;
        border-color: #faf2cc;
        color: #8a6d3b;
    }

    &.error {
        background-color: #f2dede;
        border-color: #ebcccc;
        color: #a94442;
    }
}

#currentWork {
    background-color: #e7e7e6;
    color: #333333;
    font-size: 0.9em;
    width: 100%;

    .separator {
        display: inline-block;
        width: 2px;
        height: 16px;
        position: relative;
        top: 2px;
        background-color: #333333;
        margin: 0px 7px;
    }

    td, th {
        vertical-align: top;
        padding: 0.5em;
        text-align: left;
        border-bottom: solid 1px #d9d9d9;
        background-color: #f7f6f6;
        /*background: -moz-linear-gradient(top, #f7f6f6 0%, #eeeeee 100%);
        background: -webkit-linear-gradient(top, #f7f6f6 0%,#eeeeee 100%);
        background: linear-gradient(to bottom, #f7f6f6 0%,#eeeeee 100%);*/
    }

    tr.qaHead td {
        border-bottom: none;
    }

    tr td:first-child, tr td:nth-child(2) {
        width: 1%;
        white-space: nowrap;
    }

    tr.noBorder {
        border-bottom: none;
        td {
            border-bottom: none;
        }
    }

    table {

        td {
            width: auto !important;
            background: none;
            border: none;
            padding: 0.1em 0.5em 0.1em 0px;
            font-size: 0.9em;
        }

        tr td:nth-child(2) {
            text-align: right;
        }
    }

    .cycleTime {
        font-style: italic;
        font-size: 0.9em;
    }

    .boxName {
        padding-top: 0.2em;
        font-style: italic;
        font-size: 0.9em;
    }

    .rightButtons {
        position: absolute;
        right: 0.5em;
    }
}

#codeReader {
    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: #000000;
    text-align: left;

    .topOverlay,
    .bottomOverlay,
    .leftOverlay,
    .rightOverlay {
        position: absolute;
        background-color: #000000;
        opacity: 0.3;
    }

    .topOverlay {
        height: 40%;
        width: 100%;
        border-bottom: solid 5px #ff0000;
    }

    .bottomOverlay {
        height: 40%;
        width: 100%;
        bottom: 0px;
        border-top: solid 5px #ff0000;
    }

    .inner {
        position: absolute;
        width: 600px;
        top: 10px;
        left: 50%;
        margin: 0px 0px 0px -300px;
        border: solid 1px #333333;
        padding: 0px;
        text-align: left;
    }

    .close {
        text-align: right;
        padding: 2px 1px 0px 0px;
        background-image: url('/img/bg-body.png');
        font-size: 1.5em;
        cursor: pointer;
        position: static;
    }

    .scanner {
        border-bottom: solid 1px #333333;
        position: relative;
    }

    .separator {
        display: inline-block;
        width: 2px;
        height: 18px;
        position: relative;
        top: 4px;
        background-color: #333333;
        margin: 0px 7px 0px 9px;
    }

    .overlay {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        z-index: 9;

        .icon {
            position: absolute;
            font-size: 6em;
            top: 50%;
            left: 50%;
            margin-top: -0.5em;
            margin-left: -0.4em;
        }
    }

    .line {
        padding: 7px 10px;
        position: relative;
        background-image: url('/img/bg-body.png');

        .icons {
            position: absolute;
            right: 0.1em;
            top: 0px;
        }
    }

}

#quaggaContainer {
    position: fixed;
    left: 50%;
    z-index: 1000;
    top: 44px;
    width: 600px;
    margin-left: -299px;
}

#quagga {

    video {
        width: 100%;
    }

    .drawingBuffer {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
}

.hidden {
    display: none !important;
}

.editPencil {
    font-size: 1.1em;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    margin-left: 10px;
    margin-top: -1px;

    &:hover {
        color: #bf342d;
    }
}

.checking {
    margin: 0px 0px 2em 0px;

    button {
        padding: 0.7em 0.3em;
        min-width: 70px;
        margin: 0px 0.3em 0.5em 0.3em;
    }
}

.notice {
    display: inline-block;
    //margin: 5px 0px;
    position: relative;

    textarea {
        height: 200px;
        padding: 0.3em 0.5em;
        margin: 0px 0px 1.5em 0px;
        font-size: 1em;
        border-color: #c0bfbf;
        width: 90%;
        max-width: 600px;
    }
}

.noticeButton {
    white-space: nowrap;
}

#currentWork {

    .notice .flashMessage {
        font-size: 1.1em;

        td {
            padding: 0px;
            font-size: 1em;
        }
    }

}

.securityNotices {
    width: 90%;
    max-width: 600px;
    padding-left: 0px;
    position: relative;
    left: 5px;
    margin: 0px auto;
    line-height: 1.5em;
    font-size: 1em;
    text-align: left;

    li {
        margin: 1em 0px;
    }
}

.hideMe {
    -moz-animation: cssAnimation 0s ease-in 3s forwards;
    -webkit-animation: cssAnimation 0s ease-in 3s forwards;
    -o-animation: cssAnimation 0s ease-in 3s forwards;
    animation: cssAnimation 0s ease-in 3s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}

@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}

#footer {
    position: fixed;
    bottom: 0px;
    width: calc(100% - 20px);
    background-color: #e3e3e2;
    border-top: solid 1px #c3c2c1;
    padding: 0.4em 0.7em;
    z-index: 1;
    font-size: 0.8em;
    text-align: right;
}

.allChecked {
    color: #bf342d;
    font-weight: bold;
    font-style: italic;
    position: relative;
    top: 0.5em;
}

.statsTable {
    width: 96%;
    max-width: 600px;
    margin: 0px auto;
    font-size: 0.9em;

    td, th {
        padding: 0.5em;
    }
}

.defectsTable {
    width: 96%;
    max-width: 800px;
    margin: 0px auto 30px auto;
    font-size: 1em;

    .notice {
        margin: 0px;
    }

    td, th {
        padding: 0.5em;
    }

    .jobTitle {
        display: block;
        font-size: 0.85em;
        font-weight: normal;
        font-style: italic;
        margin-top: 0.2em;
    }

    .batchError {
        color: #bf342d;
        font-weight: normal;
        font-style: italic;
        font-size: 0.85em;
        margin-top: 0.3em;
    }
}

.headRow th {
    background-color: #dddddd;
    font-weight: bold;
    border-bottom-width: 1px;
}

.sumRow td {
    background-color: #dddddd;
    font-weight: bold;
    border-top-width: 1px;
}

.empty {
    font-style: italic;
    color: #666666;
    font-size: 0.9em;
}

.jobsTable {
    width: 96%;
    max-width: 1000px;
    margin: 0px auto;
    font-size: 0.9em;
    color: #000000;

    button {
        width: auto;
        min-width: auto;
        margin: 2px 0px;
        padding: 8px 15px;
    }

    td, th {
        padding: 0.5em;
    }
}

table {

    input {
        margin: 5px 0px;
        padding: 7px 10px;
        width: 70px;
        text-align: center;
    }
}

#handScan {
    height: 47px;
    left: 2.2em;
    top: 1px;
    background-color: #ffffff;
    z-index: 1;
    position: absolute;
    width: calc(100% - 4.4em);

    .text {
        padding-top: 15px;
        color: #757575;
        font-style: italic;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 12px;
        font-size: 1.5em;
        cursor: pointer;
        color: #333333;

        &:hover {
            color: #bf342d;
        }
    }

    input {
        width: 0px;
        height: 0px;
        border: none;
    }
}

#fontSize {
    display: inline-block;
    color: #333333;
    font-size: 0.9em;
    margin-left: 0.5em;
    margin-right: 0.5em;

    div.icon {
        cursor: pointer;

        &.disabled {
            cursor: default;
            color: #cccccc;
        }
    }
}

.testScan {
    textarea {
        width: 100%;
        font-size: 1em;
        padding: 1em 0em;
        border: solid 1px #e3e3e2;
        height: 1em;
        text-align: center;
    }

    .delete {
        cursor: pointer;
        display: inline-block;
        margin-left: 0.5em;

        &:hover {
            color: #bf342d;
        }
    }
}

.environmentMessage {
    color: #bf342d;
    float: left;
    font-weight: bold;
}

.qaItem {
    textarea {
        height: 70px;
        padding: 0.3em 0.5em;
        margin: 0px 0px 1.5em 0px;
        font-size: 1em;
        border-color: #c0bfbf;
        width: 96%;
        max-width: calc(1000px - 1em);
    }
}

.documents {
    img {
        &.thumb {
            width: 100px;
            border: solid 1px #000000;
            cursor: pointer;

            &:hover {
                border: solid 1px #d3db38;
            }
        }

        &.full {
            width: 50%;
        }
    }
}

.webcam {
    display: inline-block;
    border: solid 1px #000000;
    margin: 0px;
    padding: 0px;
    min-width: 640px;
    min-height: 480px;
    text-align: center;
    position: relative;

    video {
        display: block;
        margin: 0px;
        padding: 0px;
    }

    .empty {
        display: block;
        margin-top: 38%;
    }

    .snap {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        transition: background-color 200ms linear;

        &.visible {
            background-color: rgba(255, 255, 255, 0.5);
            transition: background-color 0ms linear;
        }
    }
}

.bottomButtons {
    position: absolute;
    right: 10px;
    bottom: 30px;
    font-size: 1em;
}

td.requiredDocuments {

    padding: 0em;

    .count {
        font-weight: bold;
        font-size: 1em;
        display: block;
        margin-right: 6px;
        margin-bottom: 3px;
        margin-top: 5px;
        text-align: right;
    }

    .jobsTable {
        font-size: 0.8em;
    }
}

.red {
    color: #bf342d;
}

.badge {
    color: #000000 !important;
    opacity: 0.9;

    &:hover {
        opacity: 1;
    }

    &.disabled {
        opacity: 0.5;

        &:hover {
            opacity: 0.6;
        }
    }

    &.absent {
        background: #69b34c;
    }

    &.present {
        background: #ff0d0d;
    }

    &.repaired {
        background: #fab733;
    }
}

.qaItemStatus {
    font-size: 1.1em;

    &.absent {
        color: #69b34c;
    }

    &.present {
        color: #ff0d0d;
    }

    &.repaired {
        color: #fab733;
    }
}
