body #loader {
    /*position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 99;*/
}

body.loading #loader .inner {
    display: block !important;
}

body #loader .inner {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 1000;
    font-size: 0.9em;
    font-weight: bold;
    padding: 5px 5px 10px 100px;
    display: none;
}

.spinner {
    display: inline-block;
    margin: 0px auto 0;
    width: 22px;
    text-align: left;
}

.spinner > div {
    width: 3px;
    height: 3px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

.spinner.error > div {
    background-color: #a94442;
    margin: 0px 0px 0px 4px;
}