.touchDevice .react-tooltip-lite {
    display: none !important;
}

.touchDevice .react-tooltip-lite-arrow {
    display: none !important;
}

.target {
    cursor: pointer;
    display: inline-block;
}

.target .react-tooltip-lite {
    cursor: default;
}

.target:hover {
    color: #bf342d;
}

.flex-spread {
    display: flex;
    justify-content: space-between;
}

.tip-heading {
    margin: 0 0 10px;
}

.tip-list {
    margin: 0;
    padding: 0 0 0 15px;
}

.tip-list li {
    margin: 5px 0;
    padding: 0;
}

/* tooltip styles */
.react-tooltip-lite {
    background: #333;
    color: white;
    white-space: nowrap;
    width: auto !important;
    font-size: 0.9em;
}

.react-tooltip-lite a {
    color: #86b0f4;
    text-decoration: none;
}

.react-tooltip-lite a:hover {
    color: #4286f4;
}

.react-tooltip-lite-arrow {
    border-color: #333;
}

/* overrides with a custom class */
.customTip .react-tooltip-lite {
    border: 1px solid #888;
    background: #ccc;
    color: black;
}

.customTip .react-tooltip-lite-arrow {
    border-color:  #444;
    position: relative;
}

.customTip .react-tooltip-lite-arrow::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    z-index: 99;
    display: block;
}

.customTip .react-tooltip-lite-up-arrow::before {
    border-top: 10px solid #ccc;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -10px;
    top: -11px;
}

.customTip .react-tooltip-lite-down-arrow::before {
    border-bottom: 10px solid #ccc;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -10px;
    bottom: -11px;
}

.customTip .react-tooltip-lite-right-arrow::before {
    border-right: 10px solid #ccc;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    right: -11px;
    top: -10px;
}

.customTip .react-tooltip-lite-left-arrow::before {
    border-left: 10px solid #ccc;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -11px;
    top: -10px;
}

.imageWrapper {
    margin: 50px 0 0;
    position: relative;
}

.imageWrapper img {
    width: 500px;
    height: 500px;
}

.controlled-example {
    max-width: 250px;
}

.controlled-example_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
}

.controlled-example_close-button {
    cursor: pointer;
}

.controlled-example_close-button:hover {
    color: grey;
}
