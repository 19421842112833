html, body {
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  background-image: url("/img/bg-body.png");
  background-position: top right;
  font-family: sans-serif;
  color: #565656;
  font-size: 1.3em;
}

input, button {
  font-family: sans-serif;
}

#heading {
  height: 150px;
  background-image: url("/img/bg.png");
  background-repeat: no-repeat;
  background-position: bottom left;
  position: relative;
  border-bottom: solid 1px #e3e3e2;
}

#logo {
  position: absolute;
  right: 1%;
  bottom: 6%;
}
#logo img {
  width: 90px;
}

#loginButton {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #333333;
}

.loading #langSelect, .loading .validateMessage {
  display: none !important;
}
.loading .errorInput {
  border-color: #e3e3e2 !important;
}

#langSelect {
  position: absolute;
  top: 5px;
  right: 0px;
  color: #333333;
}
#langSelect span {
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  font-size: 0.9em;
  color: #333333;
  border-right: solid 1px #333333;
  padding: 0px 0.7em;
}
#langSelect span:last-child {
  border-right: none;
}
#langSelect span.selected {
  cursor: default;
  font-weight: bold;
}

h1, h2, h3, h4 {
  margin: 0px 0px 1em 0px;
  padding: 0px;
  font-size: 2em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.2em;
}

a, .anchor {
  cursor: pointer;
  color: #333333;
  text-decoration: none;
}

a:hover, .anchor:hover {
  color: #bf342d;
}

.iconAnchor {
  font-size: 1.5em;
  display: inline-block;
}

.codeSwitch {
  position: relative;
  top: 0px;
  top: 4px;
  padding: 0px 5px;
}

#content {
  padding: 50px 0px;
  margin-bottom: 30px;
  position: relative;
}

.center {
  text-align: center;
}

input {
  width: 70%;
  padding: 15px;
  font-size: 1em;
  border: solid 1px #e3e3e2;
  margin-bottom: 20px;
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

button {
  background: #d3db38;
  background: linear-gradient(to bottom, #d3db38 0%, #9fa521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d3db38", endColorstr="#9fa521",GradientType=0 );
  color: #333333;
  text-transform: uppercase;
  padding: 0.7em 1.4em;
  font-size: 1em;
  border-width: 0px;
  min-width: 15em;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
button:hover {
  background: #d3db38;
}
button.smallButton {
  min-width: 50px;
  padding: 0.6em 0.8em;
  font-size: 0.9em;
}
button.subdued {
  background: #c9cfd6;
  background: linear-gradient(to bottom, #c9cfd6 0%, #d7dade 100%);
}
button.subdued:hover {
  background: #d7dade;
}

button[disabled], button.disabled {
  background: #cccccc;
  color: #666666;
  cursor: default;
}

button[disabled]:hover, button.disabled {
  background: #cccccc;
}

button.pointer {
  cursor: pointer;
}

.formInput {
  width: 70%;
  margin: 0px auto 30px auto;
  position: relative;
}
.formInput input {
  width: 100%;
  padding: 15px 0px;
  margin: 0px 0px 0.2em 0px;
  text-align: center;
  background-image: none !important;
}
.formInput label {
  display: block;
  text-align: left;
  margin: 0px 0px 5px 5px;
}
.formInput .validateMessage {
  text-align: right;
  color: #bf342d;
  font-size: 0.8em;
}
.formInput .errorInput {
  border-color: #bf342d;
}
.formInput .scanIcon {
  position: absolute !important;
  right: 0.3em;
  margin-top: 12px;
  z-index: 1;
}
.formInput .scanIcon2 {
  position: absolute !important;
  right: 2.9em;
  margin-top: 14px;
  z-index: 1;
}
.formInput .inputContainer {
  position: relative;
  margin-bottom: 1em;
}
.formInput .flashMessage {
  margin: 0px 0em 1.5em 0em;
}
.formInput .flashMessage .inner {
  padding: 0.7em 2.5em 0.7em 1em !important;
}

.flashMessage {
  margin: 0px 1.5em 1.5em 1.5em;
  position: relative;
  border: solid 1px;
}
.flashMessage .inner {
  text-align: center;
  padding: 0.7em 2.5em 0.7em 1em;
}
.flashMessage .close {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
  font-size: 1.3em;
}
.flashMessage.fullScreen {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  border-width: 0px;
  overflow: auto;
}
.flashMessage.fullScreen .inner {
  padding: 0px 0px 10% 0px;
}
.flashMessage.fullScreen .close {
  right: 1em;
  top: 1em;
  font-size: 1.5em;
}
.flashMessage.common {
  background-color: #e7e7e6;
  border-color: #666666;
  color: #333333;
  background-image: url("/img/bg-body.png");
}
.flashMessage.info {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}
.flashMessage.alert {
  background-color: #fcf8e3;
  border-color: #faf2cc;
  color: #8a6d3b;
}
.flashMessage.error {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

#currentWork {
  background-color: #e7e7e6;
  color: #333333;
  font-size: 0.9em;
  width: 100%;
}
#currentWork .separator {
  display: inline-block;
  width: 2px;
  height: 16px;
  position: relative;
  top: 2px;
  background-color: #333333;
  margin: 0px 7px;
}
#currentWork td, #currentWork th {
  vertical-align: top;
  padding: 0.5em;
  text-align: left;
  border-bottom: solid 1px #d9d9d9;
  background-color: #f7f6f6;
  /*background: -moz-linear-gradient(top, #f7f6f6 0%, #eeeeee 100%);
  background: -webkit-linear-gradient(top, #f7f6f6 0%,#eeeeee 100%);
  background: linear-gradient(to bottom, #f7f6f6 0%,#eeeeee 100%);*/
}
#currentWork tr.qaHead td {
  border-bottom: none;
}
#currentWork tr td:first-child, #currentWork tr td:nth-child(2) {
  width: 1%;
  white-space: nowrap;
}
#currentWork tr.noBorder {
  border-bottom: none;
}
#currentWork tr.noBorder td {
  border-bottom: none;
}
#currentWork table td {
  width: auto !important;
  background: none;
  border: none;
  padding: 0.1em 0.5em 0.1em 0px;
  font-size: 0.9em;
}
#currentWork table tr td:nth-child(2) {
  text-align: right;
}
#currentWork .cycleTime {
  font-style: italic;
  font-size: 0.9em;
}
#currentWork .boxName {
  padding-top: 0.2em;
  font-style: italic;
  font-size: 0.9em;
}
#currentWork .rightButtons {
  position: absolute;
  right: 0.5em;
}

#codeReader {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #000000;
  text-align: left;
}
#codeReader .topOverlay,
#codeReader .bottomOverlay,
#codeReader .leftOverlay,
#codeReader .rightOverlay {
  position: absolute;
  background-color: #000000;
  opacity: 0.3;
}
#codeReader .topOverlay {
  height: 40%;
  width: 100%;
  border-bottom: solid 5px #ff0000;
}
#codeReader .bottomOverlay {
  height: 40%;
  width: 100%;
  bottom: 0px;
  border-top: solid 5px #ff0000;
}
#codeReader .inner {
  position: absolute;
  width: 600px;
  top: 10px;
  left: 50%;
  margin: 0px 0px 0px -300px;
  border: solid 1px #333333;
  padding: 0px;
  text-align: left;
}
#codeReader .close {
  text-align: right;
  padding: 2px 1px 0px 0px;
  background-image: url("/img/bg-body.png");
  font-size: 1.5em;
  cursor: pointer;
  position: static;
}
#codeReader .scanner {
  border-bottom: solid 1px #333333;
  position: relative;
}
#codeReader .separator {
  display: inline-block;
  width: 2px;
  height: 18px;
  position: relative;
  top: 4px;
  background-color: #333333;
  margin: 0px 7px 0px 9px;
}
#codeReader .overlay {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: 9;
}
#codeReader .overlay .icon {
  position: absolute;
  font-size: 6em;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.4em;
}
#codeReader .line {
  padding: 7px 10px;
  position: relative;
  background-image: url("/img/bg-body.png");
}
#codeReader .line .icons {
  position: absolute;
  right: 0.1em;
  top: 0px;
}

#quaggaContainer {
  position: fixed;
  left: 50%;
  z-index: 1000;
  top: 44px;
  width: 600px;
  margin-left: -299px;
}

#quagga video {
  width: 100%;
}
#quagga .drawingBuffer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.hidden {
  display: none !important;
}

.editPencil {
  font-size: 1.1em;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  margin-left: 10px;
  margin-top: -1px;
}
.editPencil:hover {
  color: #bf342d;
}

.checking {
  margin: 0px 0px 2em 0px;
}
.checking button {
  padding: 0.7em 0.3em;
  min-width: 70px;
  margin: 0px 0.3em 0.5em 0.3em;
}

.notice {
  display: inline-block;
  position: relative;
}
.notice textarea {
  height: 200px;
  padding: 0.3em 0.5em;
  margin: 0px 0px 1.5em 0px;
  font-size: 1em;
  border-color: #c0bfbf;
  width: 90%;
  max-width: 600px;
}

.noticeButton {
  white-space: nowrap;
}

#currentWork .notice .flashMessage {
  font-size: 1.1em;
}
#currentWork .notice .flashMessage td {
  padding: 0px;
  font-size: 1em;
}

.securityNotices {
  width: 90%;
  max-width: 600px;
  padding-left: 0px;
  position: relative;
  left: 5px;
  margin: 0px auto;
  line-height: 1.5em;
  font-size: 1em;
  text-align: left;
}
.securityNotices li {
  margin: 1em 0px;
}

.hideMe {
  -webkit-animation: cssAnimation 0s ease-in 3s forwards;
  animation: cssAnimation 0s ease-in 3s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
#footer {
  position: fixed;
  bottom: 0px;
  width: calc(100% - 20px);
  background-color: #e3e3e2;
  border-top: solid 1px #c3c2c1;
  padding: 0.4em 0.7em;
  z-index: 1;
  font-size: 0.8em;
  text-align: right;
}

.allChecked {
  color: #bf342d;
  font-weight: bold;
  font-style: italic;
  position: relative;
  top: 0.5em;
}

.statsTable {
  width: 96%;
  max-width: 600px;
  margin: 0px auto;
  font-size: 0.9em;
}
.statsTable td, .statsTable th {
  padding: 0.5em;
}

.defectsTable {
  width: 96%;
  max-width: 800px;
  margin: 0px auto 30px auto;
  font-size: 1em;
}
.defectsTable .notice {
  margin: 0px;
}
.defectsTable td, .defectsTable th {
  padding: 0.5em;
}
.defectsTable .jobTitle {
  display: block;
  font-size: 0.85em;
  font-weight: normal;
  font-style: italic;
  margin-top: 0.2em;
}
.defectsTable .batchError {
  color: #bf342d;
  font-weight: normal;
  font-style: italic;
  font-size: 0.85em;
  margin-top: 0.3em;
}

.headRow th {
  background-color: #dddddd;
  font-weight: bold;
  border-bottom-width: 1px;
}

.sumRow td {
  background-color: #dddddd;
  font-weight: bold;
  border-top-width: 1px;
}

.empty {
  font-style: italic;
  color: #666666;
  font-size: 0.9em;
}

.jobsTable {
  width: 96%;
  max-width: 1000px;
  margin: 0px auto;
  font-size: 0.9em;
  color: #000000;
}
.jobsTable button {
  width: auto;
  min-width: auto;
  margin: 2px 0px;
  padding: 8px 15px;
}
.jobsTable td, .jobsTable th {
  padding: 0.5em;
}

table input {
  margin: 5px 0px;
  padding: 7px 10px;
  width: 70px;
  text-align: center;
}

#handScan {
  height: 47px;
  left: 2.2em;
  top: 1px;
  background-color: #ffffff;
  z-index: 1;
  position: absolute;
  width: calc(100% - 4.4em);
}
#handScan .text {
  padding-top: 15px;
  color: #757575;
  font-style: italic;
}
#handScan .close {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 1.5em;
  cursor: pointer;
  color: #333333;
}
#handScan .close:hover {
  color: #bf342d;
}
#handScan input {
  width: 0px;
  height: 0px;
  border: none;
}

#fontSize {
  display: inline-block;
  color: #333333;
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
#fontSize div.icon {
  cursor: pointer;
}
#fontSize div.icon.disabled {
  cursor: default;
  color: #cccccc;
}

.testScan textarea {
  width: 100%;
  font-size: 1em;
  padding: 1em 0em;
  border: solid 1px #e3e3e2;
  height: 1em;
  text-align: center;
}
.testScan .delete {
  cursor: pointer;
  display: inline-block;
  margin-left: 0.5em;
}
.testScan .delete:hover {
  color: #bf342d;
}

.environmentMessage {
  color: #bf342d;
  float: left;
  font-weight: bold;
}

.qaItem textarea {
  height: 70px;
  padding: 0.3em 0.5em;
  margin: 0px 0px 1.5em 0px;
  font-size: 1em;
  border-color: #c0bfbf;
  width: 96%;
  max-width: calc(1000px - 1em);
}

.documents img.thumb {
  width: 100px;
  border: solid 1px #000000;
  cursor: pointer;
}
.documents img.thumb:hover {
  border: solid 1px #d3db38;
}
.documents img.full {
  width: 50%;
}

.webcam {
  display: inline-block;
  border: solid 1px #000000;
  margin: 0px;
  padding: 0px;
  min-width: 640px;
  min-height: 480px;
  text-align: center;
  position: relative;
}
.webcam video {
  display: block;
  margin: 0px;
  padding: 0px;
}
.webcam .empty {
  display: block;
  margin-top: 38%;
}
.webcam .snap {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: background-color 200ms linear;
}
.webcam .snap.visible {
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0ms linear;
}

.bottomButtons {
  position: absolute;
  right: 10px;
  bottom: 30px;
  font-size: 1em;
}

td.requiredDocuments {
  padding: 0em;
}
td.requiredDocuments .count {
  font-weight: bold;
  font-size: 1em;
  display: block;
  margin-right: 6px;
  margin-bottom: 3px;
  margin-top: 5px;
  text-align: right;
}
td.requiredDocuments .jobsTable {
  font-size: 0.8em;
}

.red {
  color: #bf342d;
}

.badge {
  color: #000000 !important;
  opacity: 0.9;
}
.badge:hover {
  opacity: 1;
}
.badge.disabled {
  opacity: 0.5;
}
.badge.disabled:hover {
  opacity: 0.6;
}
.badge.absent {
  background: #69b34c;
}
.badge.present {
  background: #ff0d0d;
}
.badge.repaired {
  background: #fab733;
}

.qaItemStatus {
  font-size: 1.1em;
}
.qaItemStatus.absent {
  color: #69b34c;
}
.qaItemStatus.present {
  color: #ff0d0d;
}
.qaItemStatus.repaired {
  color: #fab733;
}
.touchDevice .react-tooltip-lite {
  display: none !important;
}

.touchDevice .react-tooltip-lite-arrow {
  display: none !important;
}

.target {
  cursor: pointer;
  display: inline-block;
}

.target .react-tooltip-lite {
  cursor: default;
}

.target:hover {
  color: #bf342d;
}

.flex-spread {
  display: flex;
  justify-content: space-between;
}

.tip-heading {
  margin: 0 0 10px;
}

.tip-list {
  margin: 0;
  padding: 0 0 0 15px;
}

.tip-list li {
  margin: 5px 0;
  padding: 0;
}

/* tooltip styles */
.react-tooltip-lite {
  background: #333;
  color: white;
  white-space: nowrap;
  width: auto !important;
  font-size: 0.9em;
}

.react-tooltip-lite a {
  color: #86b0f4;
  text-decoration: none;
}

.react-tooltip-lite a:hover {
  color: #4286f4;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

/* overrides with a custom class */
.customTip .react-tooltip-lite {
  border: 1px solid #888;
  background: #ccc;
  color: black;
}

.customTip .react-tooltip-lite-arrow {
  border-color: #444;
  position: relative;
}

.customTip .react-tooltip-lite-arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.customTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.customTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.customTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.customTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.imageWrapper {
  margin: 50px 0 0;
  position: relative;
}

.imageWrapper img {
  width: 500px;
  height: 500px;
}

.controlled-example {
  max-width: 250px;
}

.controlled-example_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.controlled-example_close-button {
  cursor: pointer;
}

.controlled-example_close-button:hover {
  color: grey;
}
body #loader {
  /*position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 99;*/
}

body.loading #loader .inner {
  display: block !important;
}

body #loader .inner {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1000;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 5px 10px 100px;
  display: none;
}

.spinner {
  display: inline-block;
  margin: 0px auto 0;
  width: 22px;
  text-align: left;
}

.spinner > div {
  width: 3px;
  height: 3px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.spinner.error > div {
  background-color: #a94442;
  margin: 0px 0px 0px 4px;
}
@media (max-width: 700px) {
  #codeReader {
    top: 0px;
  }
  #codeReader .inner {
    top: 10px;
    width: 90%;
    margin-left: 0px;
    left: 5%;
  }

  button {
    min-width: 300px;
  }

  #quaggaContainer {
    width: 90%;
    left: 5%;
    top: 44px;
    margin-left: 1px;
  }

  .environmentMessage {
    float: none;
  }
}
@media (max-width: 480px) {
  body {
    font-size: 0.9em;
  }

  #content {
    padding: 30px 0px;
  }

  .flashMessage {
    margin: 0px 20px 30px 20px;
  }
  .flashMessage .inner {
    padding: 10px 35px 10px 10px;
  }

  .formInput {
    width: 80%;
  }

  .hideMobile {
    display: none;
  }

  #currentWork .separator {
    width: 1px;
    height: 11px;
  }

  #currentWork {
    font-size: 1em;
  }

  button {
    min-width: 200px;
  }

  .flashMessage.fullScreen .close {
    font-size: 2em;
    right: 20px;
    top: 20px;
  }

  #heading {
    height: 100px;
  }

  #logo {
    right: 10px;
    bottom: 10px;
    top: auto;
    margin-top: auto;
  }
  #logo img {
    width: 60px;
  }

  #time {
    top: 25px;
  }

  #quaggaContainer {
    top: 39px;
  }

  .formInput .scanIcon2 {
    margin-top: 13px;
    right: 45px;
  }

  #handScan .close {
    right: 0px;
  }

  #handScan .text {
    padding-right: 50px;
  }
}
