@media (max-width: 700px) {

    #codeReader {
        top: 0px;

        .inner {
            top: 10px;
            width: 90%;
            margin-left: 0px;
            left: 5%;
        }
    }

    button {
        min-width: 300px;
    }

    #quaggaContainer {
        width: 90%;
        left: 5%;
        top: 44px;
        margin-left: 1px;
    }

    .environmentMessage {
        float: none;
    }

}



@media (max-width: 480px) {

    body {
        font-size: 0.9em;
    }

    #content {
        padding: 30px 0px;
    }

    .flashMessage {
        margin: 0px 20px 30px 20px;

        .inner {
            padding: 10px 35px 10px 10px;
        }
    }

    .formInput {
        width: 80%;
    }

    .hideMobile {
        display: none;
    }

    #currentWork .separator {
        width: 1px;
        height: 11px;
    }

    #currentWork {
        font-size: 1em;
    }

    button {
        min-width: 200px;
    }

    .flashMessage.fullScreen .close {
        font-size: 2em;
        right: 20px;
        top: 20px;
    }

    #heading {
        height: 100px;
    }

    #logo {
        right: 10px;
        bottom: 10px;
        top: auto;
        margin-top: auto;

        img {
            width: 60px;
        }
    }


    #time {
        top: 25px;
    }

    #quaggaContainer {
        top: 39px;
    }

    .formInput .scanIcon2 {
        margin-top: 13px;
        right: 45px;
    }

    #handScan .close {
        right: 0px;
    }

    #handScan .text {
        padding-right: 50px;
    }

}
